<template>
  <div class="container text-dark">
    <!-- Modal Rincian -->
    <a-modal bodyStyle="height: 400px; overflow: auto;" :title="showBilled ? 'Rincian Total Tagihan' : 'Total Credit Limit Terpakai'" id="modal-response" :width="600" v-model="modalDetail" :footer="null" hide-body centered class="p-3">
      <div style="" v-if="(!showBilled && listSummary.length > 0) || (showBilled && billedListSummary.length > 0)">
        <div class="d-flex flex-column" v-for="({ expired_time, order_number, order, total_bills, order_id }, key) in showBilled ? billedListSummary : listSummary" :key="key">
          <div class="d-flex flex-column p-2">
            <div class="d-flex justify-content-between">
              <span class="h5">{{ order_number || order }}</span>
              <div class="d-flex flex-column align-items-end">
                <div style="color: #767676">Batas waktu pelunasan: </div>
                <div class="text-dark ml-1">{{ expired_time }}</div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column">
                <span class="h6 my-1" style="color: #767676">Tagihan</span>
                <span class="h6 my-1" style="color: var(--biz-secondary-100);">{{ total_bills }}</span>
              </div>
              <div class="d-flex align-items-end">
                <div style="border: solid 1px var(--biz-primary-100); color: var(--biz-primary-100)"
                  class="btn d-flex align-items-center justify-content-center rounded my-2 w-100">
                  <span class="h6 mb-0" @click.stop="showDetail(order_id)">Lihat Detail</span>
                </div>
              </div>
            </div>
          </div>
          <a-divider />
        </div>
      </div>
      <div class="text-center text-dark py-4" v-else>
        Tidak ada tagihan
      </div>
    </a-modal>
    <b-modal id="modal-response" v-model="modalPaymentMethod" centered class="p-3">
      <div slot="modal-header" class="d-flex align-items-center justify-content-between p-2 w-100">
        <span class="h4 mb-0 text-primary">{{ $t('purchase.checkout.select_payment_method') }} </span>
        <span @click="modalPaymentMethod = false" class="h4 mb-0 text-primary hover">x</span>
      </div>
      <div class="d-flex flex-column text-dark">
        <a-radio-group class="w-100" v-model="selected_method">
          <b-card no-body class="mb-3" v-for="({ title, children }, key) in listPaymentMethod" :key="key">
            <b-card-header header-tag="header" class="p-0 toggle-btn" :class="activeKey === key && 'collapseActive'"
              style="border: solid 0px white">
              <b-button @click="openCollapse(key)" block variant="info" style="border: none; background-color: #F6F6F6"
                class="py-2 px-3 hover flex-row">
                <div class="d-flex justify-content-between align-items-center text-dark">
                  <span>{{ title }}</span>
                  <!-- <span class="h4 mb-0">></span> -->
                  <span class="arrow h3 mb-0"></span>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse :id="`accordion-${key}`" :visible="activeKey === key" role="tabpanel">
              <b-card-body v-for="({ name, component }, index) in children" :key="index" class="py-3">
                <div class="d-flex justify-content-between align-items-center">
                  <!-- <component v-bind:is="component"></component> -->
                  <img :width="73" :src="component" />
                  <span>{{ name }}</span>
                  <a-radio :value="name" class="list-card" />
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </a-radio-group>
      </div>
      <div slot="modal-footer" style="border: none"
        class="d-flex align-items-center rounded justify-content-between p-00 w-100">
        <div class="w-100 bg-primary p-1 text-white justify-content-center hover d-flex"
          :class="!selected_method && 'bg-secondary'" @click="applyMethod(selected_method)">
          <span>Konfirmasi</span>
        </div>
      </div>
    </b-modal>

    <span class="h3">Bayar Tagihan Pembayaran Tempo</span>
    <div class="d-flex flex-column mt-5 pl-5">
      <a-row>
        <a-col :span="24" class="border card">
          <div class="px-5 py-4 border-bottom">
            <div class="card-header">
              <span class="h5">Informasi Credit Limit</span>
              <div class="d-flex flex-column mt-5">
                <div class="d-flex justify-content-between mb-3 align-items-center">
                  <span>Distributor</span>
                  <span>{{ distributorDetail && distributorDetail.name }}</span>
                </div>
                <div class="d-flex justify-content-between mb-3 align-items-center">
                  <span>Status</span>
                  <div class="p-2"
                    style="border-radius: 16px; color: var(--biz-primary-100); border: 1px solid var(--biz-primary-100); background-color: var(--biz-secondary-20);">
                    {{creditLimitData && creditLimitData.bond_type}}
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3 align-items-center">
                  <span>Total Credit Limit</span>
                  <span style="color: var(--biz-secondary-100)">{{toCurrencyIDR(unpaidCreditLimit && unpaidCreditLimit.creditlimit_given)}}</span>
                </div>
                <div class="d-flex justify-content-between mb-3 align-items-center">
                  <span>Sisa Credit Limit</span>
                  <span style="color: #FF0000">{{toCurrencyIDR(unpaidCreditLimit && unpaidCreditLimit.creditlimit_balance)}}</span>
                </div>
                <div class="d-flex justify-content-between mb-3 align-items-center">
                  <span>Batas Maksimal Pelunasan</span>
                  <span style="color: black">{{`${creditLimitData && creditLimitData.term_value} ${$t(`common.${creditLimitData && creditLimitData.term_unit}`)}`}}</span>
                </div>
                <div class="d-flex justify-content-between mb-3 align-items-center">
                  <span>Jatuh Tempo Terdekat</span>
                  <span style="color: var(--biz-primary-100)">{{ lastCreditNumberId }}</span>
                </div>
                <div class="d-flex justify-content-between mb-3 align-items-center">
                  <span>Tanggal Jatuh Tempo Terdekat</span>
                  <span style="color: var(--biz-primary-100)">{{ lastCreditDate }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="px-5 pt-2 border-bottom">
            <div class="card-body d-flex justify-content-between">
              <div class="d-flex flex-column">
                <span>Total Credit Limit Terpakai</span>
                <span style="color: var(--biz-primary-100); font-size: 18px;">{{ toCurrencyIDR(unpaidCreditLimit && (unpaidCreditLimit.creditlimit_usage_amount * -1)) }}</span>
              </div>
              <div style="color: var(--biz-primary-100)" @click="modalDetail = true, showBilled = false" class="text-bold hover">Lihat Rincian</div>
            </div>
          </div>
          <div class="px-5 pt-2 border-bottom">
            <div class="card-body d-flex justify-content-between">
              <div class="d-flex flex-column">
                <span>Total Tagihan</span>
                <span style="color: var(--biz-secondary-100); font-size: 18px; font-weight: bold;">{{ toCurrencyIDR(billedCreditLimit && (billedCreditLimit.creditlimit_usage_amount * -1)) }}</span>
              </div>
              <div style="color: var(--biz-primary-100)" @click="modalDetail = true, showBilled = true" class="text-bold hover">Lihat Rincian</div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row class="border card px-5 py-4">
        <a-col :span="24">
          <div class="card-body d-flex flex-column justify-content-around">
            <span class="h5">Informasi Pembayaran</span>
            <div class="py-2 px-3 d-flex align-items-center my-4"
              style="background-color: #F4F4F4; border-radius: 12px;">
              <Information :color="'#015CA1'" />
              <span class="ml-2" style="color: #015CA1">Tagihan akan terbayar mulai dari pesanan terlama pada
                distributor</span>
            </div>
            <span class="span-12">Nominal Pembayaran</span>
            <div class="my-3 d-flex justify-content-between">
              <div class="col-6 pl-0">
                <a-input :disabled="disableEditSettlement" @change="handleChangeAmount($event.target.value)" :class="`input-settlement text-dark ${!!showInvalidNominal && 'field-nominal-warning'}`" size="large" placeholder="Nominal Pembayaran" v-model="showAmount">
                  <template #prefix>
                    <div class="text-dark"> {{` Rp `}} </div>
                  </template>
                </a-input>
                <div v-if="!!showInvalidNominal" class="warning-nominal">Nominal pembayaran tidak boleh lebih besar dari tagihan</div>
                <div v-if="nearlyDueDateOrder?.order_number" class="py-2 px-3 d-flex align-items-center mt-2"
                  style="background-color: #F4F4F4; border-radius: 12px;">
                  <Information :color="'#015CA1'" />
                  <span class="ml-2" style="color: #015CA1">No. Order yang akan dibayarkan: <b>{{ nearlyDueDateOrder.order_number }}</b></span>
                </div>
              </div>
              <div class="col-6 pl-0">
                <SettlementPaymentList @setSelectedPayment="setSelectedPayment"/>
              </div>
            </div>
            <div v-if="applied_method" class="w-auto d-flex justify-content-end">
              <div class="w-50">
                <div class="d-flex justify-content-evenly align-items-center px-2 py-2 rounded"
                  style="background-color: #F6F6F6">
                  <img :width="73" :src="applied_method.component" class="mr-2" />
                  <span class="mr-2">{{ applied_method.name }}</span>
                  <a-radio :checked="true" class="list-card" />
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="w-100 d-flex justify-content-end" style="gap: 10px;">
        <button @click="$router.go(-1)" style="width: 160px" class="px-4 py-2 text-dark border justify-content-center rounded-lg d-flex hover">Kembali</button>
        <button style="width: 160px" class="px-4 py-2 button-submit rounded-lg"
          @click="requestPaymentSettlement" :disabled="loading || !inputAmount">
          <a-icon v-if="loading" type="loading" />
          <template v-else>
            Proses
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import Information from '@/components/Icons/Information.vue'
import moment from 'moment'
import { toCurrencyIDR } from '@/utils/general'
import SettlementPaymentList from '@/components/Purchase/Checkout/SettlementPaymentList'
import { mapState } from 'vuex'

const BRI = 'https://ik.imagekit.io/kxf6glqjih1/Payment_Icon/logo-bank-bri_3cPpiCLQR.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653645854606'
const Mandiri = 'https://ik.imagekit.io/kxf6glqjih1/Payment_Icon/logo-bank-mandiri_oS71swHM-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653645854823'
const BCA = 'https://ik.imagekit.io/kxf6glqjih1/Payment_Icon/logo-bank-bca_VSKe1PEUrj.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653645854611'
const Gopay = 'https://ik.imagekit.io/kxf6glqjih1/Payment_Icon/logo-gopay_kpzEppgPo.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653645855614'
const COD = 'https://ik.imagekit.io/kxf6glqjih1/Payment_Icon/logo-cod__2XIikBDS.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653645855334'

export default {
  name: 'bill-payment',
  components: {
    Information,
    SettlementPaymentList,
  },
  data() {
    return {
      loading: false,
      modalDetail: false,
      activeKey: null,
      creditLimitData: {},
      unpaidCreditLimit: {},
      billedCreditLimit: {},
      showBilled: false,
      paymentList: [],
      listSummary: [],
      billedListSummary: [],
      distributorDetail: null,
      modalPaymentMethod: false,
      selected_method: '',
      selectedPayment: null,
      applied_method: '',
      inputAmount: 0,
      showAmount: '0',
      showInvalidNominal: false,
      listContent: [
        {
          key: 'Distributor',
          content: '<span>Distributor</span>',
        },
        {
          key: 'Status',
          value: ' <div class="border"><span>Double Bon</span></div>',
        },
      ],
      listPaymentMethod: [
        {
          title: 'Virtual Account',
          children: [
            {
              component: BRI,
              name: 'BRI Virtual Account',
            },
            {
              component: BCA,
              name: 'BCA Virtual Account',
            },
            {
              component: Mandiri,
              name: 'Mandiri Virtual Account',
            },
          ],
        },
        {
          title: 'Transfer Bank (Verifikasi Manual)',
          children: [
            {
              component: BCA,
              name: 'BCA',
            },
            {
              component: Mandiri,
              name: 'Mandiri',
            },
            {
              component: BRI,
              name: 'BRI',
            },
          ],
        },
        {
          title: 'E-Wallet',
          children: [
            {
              component: Gopay,
              name: 'GoPay E-Wallet',
            },
          ],
        },
        {
          title: 'COD',
          children: [
            {
              component: COD,
              name: 'Cash On Delivery',
            },
          ],
        },
      ],
      disableEditSettlement: true,
      nearlyDueDateOrder: null,
    }
  },
  computed: {
    ...mapState(['meta']),
    lastCreditNumberId() {
      return this.listSummary.length > 0 ? this.listSummary[0].order_number : '-'
    },
    lastCreditDate() {
      return this.listSummary.length > 0 ? moment(this.listSummary[0].due).format('DD MMM YYYY') : '-'
    },
  },
  watch: {
    'billedCreditLimit': {
      deep: true,
      immediate: true,
      handler(value) {
        if (this.showAmount === '0' && this.inputAmount === 0 && value) {
          this.$store.state.meta.data.forEach((data) => {
            if (data?.meta_name === 'disable_input_settlement') {
              const { disabled, multiOrderSettlement } = JSON.parse(data?.meta_value)
              this.disableEditSettlement = disabled
              if (!disabled) return
              if (multiOrderSettlement) {
                this.setDefaultAmount(value?.creditlimit_usage_amount * -1)
              } else {
                const newArray = value?.list?.sort((a, b) => {
                  const dateA = this.$moment(a.due, 'YYYY-MM-DD HH:mm:ss')
                  const dateB = this.$moment(b.due, 'YYYY-MM-DD HH:mm:ss')
                  return dateA.diff(dateB)
                })
                if (newArray?.length > 0) {
                  this.setDefaultAmount(Math.abs(newArray[0].creditlimit_usage))
                  this.nearlyDueDateOrder = newArray[0]
                } else {
                  this.setDefaultAmount(0)
                  this.nearlyDueDateOrder = null
                }
              }
            }
          })
        }
      },
    },
  },
  methods: {
    toCurrencyIDR(value) {
      return toCurrencyIDR(value || 0)
    },
    getNumberOnly(text) {
      var numb = text.match(/\d/g)
      return numb.join('')
    },
    showDetail(param) {
      this.$router.push({ path: `/purchase/order/${param}` })
    },
    setDefaultAmount(value) {
      this.showAmount = `${Intl.NumberFormat('en-US').format(value || 0)}`
      this.inputAmount = value || 0
    },
    handleChangeAmount(value) {
      const numberOnly = this.getNumberOnly(value)
      this.showAmount = `${Intl.NumberFormat('en-US').format(numberOnly || 0)}`
      this.inputAmount = numberOnly
      if (numberOnly > this.billedCreditLimit?.creditlimit_usage_amount * -1) {
        this.showInvalidNominal = true
      } else {
        this.showInvalidNominal = false
      }
    },
    openCollapse(key) {
      if (this.activeKey === key) {
        this.activeKey = null
      } else {
        this.activeKey = key
      }
    },
    applyMethod(method) {
      const getMethodObj = this.listPaymentMethod.find(({ children }) => children.some(({ name }) => name === method)).children.find((item) => item.name === method)
      this.applied_method = getMethodObj
      this.modalPaymentMethod = false
    },
    async getLimitCredit() {
      const getCreditLimit = await this.$store.dispatch('price/GETCREDITLIMIT', {
        channel_id: this.$store.state.user.user_data.channel_id,
        seller_id: this.$route.params.seller_id,
      })
      this.creditLimitData = getCreditLimit.data
    },
    async getUnpaidCredit(bill) {
      const getUnpaidCredit = await this.$store.dispatch('billing/GETUNPAIDCREDIT', {
        channel_id: this.$store.state.user.user_data.channel_id,
        seller_id: this.$route.params.seller_id,
        params: {
          acknowledged: bill,
        },
      })
      if (bill) {
        this.billedCreditLimit = getUnpaidCredit.data
        const newSummaryList = getUnpaidCredit.data.list.map(({ due, creditlimit_usage, ...item }) => ({
          ...item,
          expired_time: moment(due, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY, HH:mm:ss'),
          total_bills: toCurrencyIDR(Math.abs(creditlimit_usage)),
        }))
        this.billedListSummary = newSummaryList
      } else {
        this.unpaidCreditLimit = getUnpaidCredit.data
        const newSummaryList = getUnpaidCredit.data.list.map(({ due, creditlimit_usage, ...item }) => ({
          ...item,
          expired_time: due ? moment(due, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY, HH:mm:ss') : 'Order dalam proses',
          total_bills: toCurrencyIDR(Math.abs(creditlimit_usage)),
        }))
        this.listSummary = newSummaryList
      }
    },
    async requestPaymentSettlement() {
      this.loading = true
      const address = await this.$store.dispatch('purchase/GETBILLINGADDRESS', {
        channel_id: this.$store.state.user.user_data.channel_id,
        addr_key: this.$store.state.user.user_data.area_id,
      })
      const getCustomerAddress = address && address.data ? address.data[0] : []
      const payload = {
        channel_id: this.$store.state.user.user_data.channel_id,
        customer: {
          id: this.$store.state.user.user_data.buyer_id,
          recipient_name: this.$store.state.user.user_data.store_name,
          first_name: this.$store.state.user.user_data.first_name,
          last_name: this.$store.state.user.user_data.last_name,
          phone: this.$store.state.user.user_data.phone,
          email: this.$store.state.user.user_data.email,
          billing_address: {
            address: getCustomerAddress ? getCustomerAddress.address_line1 : '',
            city: getCustomerAddress ? getCustomerAddress.city : '',
            postal_code: getCustomerAddress ? getCustomerAddress.postal_code : '',
          },
        },
        payment: {
          ...this.selectedPayment,
          gross_amount: {
            value: this.inputAmount,
            currency: 'IDR',
          },
          term_days: null,
        },
      }
      this.$store.dispatch('billing/REQUESTPAYMENTSETTLEMENT', {
        payload: payload,
        seller_id: this.$route.params.seller_id,
      })
        .then((res) => {
          this.loading = false
          if (res.data) {
            this.$router.push({ path: `/account/how-to-pay-bill/${res.data.payment_id}` })
          } else {
            this.$notification.error({
              message: 'Gagal',
              description: res.message || '',
            })
          }
        })
        .catch((err) => {
          this.loading = false
          this.$notification.error({
            message: 'Gagal',
            description: err.response?.data?.message || err.message,
          })
        })
    },
    setSelectedPayment(value) {
      this.selectedPayment = value
    },
    getDistributorDetail() {
      this.$store.dispatch('warehouse/GETWAREHOUSE_SELLER', {
        channel_id: this.$store.state.user.user_data.channel_id,
        seller_id: [this.$route.params.seller_id],
      })
        .then(({ data }) => {
          this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
            id: data.map((item) => item.warehouse_id),
          })
            .then(({ data }) => {
              this.distributorDetail = data.length > 0 ? data[0] : null
            })
        })
        .catch((err) => {
          this.$notification.error({
            message: err.response.statusText,
          })
        })
    },
  },
  mounted: function () {
    this.getDistributorDetail()
    this.getLimitCredit()
    this.getUnpaidCredit(false)
    this.getUnpaidCredit(true)
  },
}
</script>
<style lang="scss">
.border {
  background: #FFFFFF;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.hover:hover {
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 20px;
  right: 20px;
}

.arrow::before,
.arrow::after {
  position: relative;
  content: '';
  display: block;
  width: 10px;
  height: 1px;
  background: black;
  transition: 0.3s ease-in-out;
}

.arrow::before {
  transform: rotate(45deg);
}

.arrow::after {
  left: 6px;
  top: -1px;
  transform: rotate(-45deg);
}

.toggle-btn {
  background: #13749a;
}

.toggle-btn.collapseActive .arrow::before {
  transform: rotate(-45deg);
}

.toggle-btn.collapseActive .arrow::after {
  transform: rotate(45deg);
}

.warning-nominal {
  padding-top: 5px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF0000;
}

.field-nominal-warning .ant-input {
  border-color: #FF0000;
  &:hover {
    border-color: #FF0000 !important;
  }
  &:focus {
    border-color: #FF0000;
  }
}
.button-submit {
  width: 100%;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  color: #FFFFFF;
  background: var(--biz-brand-button);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  &[disabled] {
    opacity: .5;
  }
}
.input-settlement .ant-input[disabled] {
  color: #141322 !important;
}
</style>
