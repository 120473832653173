<template>
  <div>
    <template>
      <a-row class="checkout-cart">
        <a @click="handleSelectPayment">
          <a-col
            :span="24"
            class="payment-method px-2 py-2"
          >
            <div class="payment-method__calculation px-2">
              <div class="title-payment-method">
                <CreditCardIcon />
                <div>Pilih Metode Pembayaran</div>
              </div>
              <div>
                <a-icon
                  type="right"
                  class="float-right right-button"
                />
              </div>
            </div>
          </a-col>
        </a>
      </a-row>
      <!-- show selected items if selected -->
      <div class="selected-payment mb-3" v-if="data_method && selected_method">
        <a-radio-group
          :defultValue="1"
          name="radioGroup"
          class="selected-payment-container"
        >
          <img
            style="width: 58.3px; height: 26.51px;"
            :src="data_method.image"
          />
          <!-- {{data_method.bank}}  -->
          <span class="ml-3">{{ data_method ? data_method.payment_name : '-' }}</span>
          <a-radio class="list-card2" />
        </a-radio-group>
      </div>
    </template>
    <a-modal
      v-model="modalVisible"
      :title="$t('purchase.checkout.select_payment_method')"
      :footer="null"
      :closable="false"
      @cancel="handleCancel"
    >
      <div class="checkout-cart__pay-method">
        <template>
          <a-collapse
            class="mb-3"
            :bordered="false"
            expand-icon-position="right"
            accordion
          >
            <a-collapse-panel
              v-for="({ payment_type, payment_method }, key) in (settlementPaymentList || [])"
              :key="key"
              v-model="activeKey"
              class="text-dark"
              :header="typePaymentMap[payment_type]"
            >
              <a-radio-group
                v-model="selected_method"
                class="w-100"
              >
                <div
                  v-for="({ image, payment_name, id }, i) in payment_method"
                  :key="i"
                  style="cursor: pointer;"
                >
                  <div @click="selected_method = id" class="text-secondary d-flex flex-row align-items-center justify-content-between">
                    <div>
                      <img
                        :src="image"
                        style="width: 73.52px;"
                        :alt="i"
                        class="mr-2"
                      />
                    </div>
                    <span class="text-left" style="color: #000000">{{ payment_name }}</span>
                    <div>
                      <a-radio
                        class="list-card"
                        :value="id"
                        :disabled="payment_type.toUpperCase() === 'CAD' && invalidStatusCredit === true"
                      />
                    </div>
                  </div>
                </div>
              </a-radio-group>
            </a-collapse-panel>
          </a-collapse>
        </template>
        <a>
          <button
            :span="24"
            class="confirm-payment mt-4 mb-2 d-flex w-100 align-items-center justify-content-center"
            @click="handleSelectPayment(selected_method)"
          >
            {{ $t('purchase.checkout.confirm_order') }}
          </button>
        </a>
      </div>
    </a-modal>
  </div>
</template>

<script>
import CreditCardIcon from '@/components/Icons/CreditCard.vue'

const infoStatusCredit = [
  {
    backgroundColor: '#FDF3D1',
    textColor: '#624E17',
    textHtml: 'Sisa credit limit Anda <b>kurang dari total tagihan.</b> Tetap gunakan credit limit dengan ada pilihan pembayaran sisa atau gunakan metode pembayaran lain.',
  },
  {
    backgroundColor: '#F4F4F4',
    textColor: '#015CA1',
    textHtml: 'Mohon untuk <b>melunasi tagihan jatuh tempo</b> Anda terlebih dahulu untuk menggunakan credit limit di tagihan ini atau gunakan metode pembayaran lain.',
  },
  {
    backgroundColor: '#F4F4F4',
    textColor: '#FF0000',
    textHtml: 'Terdapat order yang telah <b>melewati batas maksimal waktu pelunasan.</b> Silahkan untuk melunasi terlebih dahulu tagihan tersebut atau gunakan metode pembayaran lain.',
  },
  {
    backgroundColor: '#F4F4F4',
    textColor: '#015CA1',
    textHtml: 'Pemakaian pembayaran tempo Anda telah mencapai batas maksimal, mohon untuk <b>melunasi tagihan jatuh tempo</b> Anda terlebih dahulu untuk menggunakan credit limit di tagihan ini atau gunakan metode pembayaran lain.',
  },
]
export default {
  props: {
    paymentList: {
      type: [Array, Object],
      default: () => [],
    },
    grossAmount: {
      type: Number,
      default: () => 0,
    },
  },
  components: {
    CreditCardIcon,
  },
  data: function () {
    return {
      payment: 'Term of Payment',
      poNumber: null,
      modalVisible: false,
      modalVisibleMixPayment: false,
      activeKey: [],
      cbd_tf_bank: [],
      cbd_va: [],
      cod: [],
      cad: [],
      selected_method: '',
      data_method: '',
      remainingPrice: 0,
      seen: false,
      seen_balance: false,
      seen_points: false,
      loyaltyPoints: 0,
      rewardAmountsApplied: [0],
      availablePoint: 0,
      size: 'large',
      x: 'one',
      selectedMethod: '',
      chooseOption: null,
      data: {
        payment_method: [],
      },
      typePaymentMap: {
        COD: 'Melalui Kurir',
        BANK_TRANSFER: 'Transfer Bank (Verifikasi Manual)',
        VA: 'Virtual Account',
        CAD: 'Pembayaran Tempo',
        EWALLET: 'E-Wallet',
      },
      tempUsedPoints: 0,
      listStatusCredit: infoStatusCredit,
      invalidStatusCredit: false,
      selectedStatusCredit: null,
      settlementPaymentList: [],
    }
  },
  computed: {
    // ...mapState(['dashboard']),
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
    options() {
      if (!this.paymentList) {
        return []
      }
      return this.paymentList.map((item) => {
        if (item.payment_method.length) {
          if (item.payment_type === 'BANK_TRANSFER') {
            const temp = item.payment_method
            this.cbd_tf_bank.push(...temp)
          } else if (item.payment_type === 'VA') {
            const temp = item.payment_method
            this.cbd_va.push(...temp)
          } else if (item.payment_type === 'COD') {
            const temp = item.payment_method
            this.cod.push(...temp)
          } else if (item.payment_type === 'CAD') {
            const temp = item.payment_method
            this.cad.push(...temp)
          } else if (item.payment_type === 'EWALLET') {
            const temp = item.payment_method
            this.ewallet.push(...temp)
          }
          const children = item.payment_method.map((value) => {
            return {
              value: value.id,
              label: value.bank_name || '-',
            }
          })

          return {
            value: item.payment_term_code,
            label: item.payment_term_title,
            children,
          }
        } else {
          return {
            value: item.payment_term_code,
            label: item.payment_term_title,
          }
        }
      })
    },
    getCreditTermLimit() {
      return `${this.data.credit_limit.term_value ?? ''} ${this.data.credit_limit.term_unit ?? '-'}`
    },
    getCreditBonds() {
      this.validationCreditLimit()
      return this.data.credit_limit.bond_type ?? '-'
    },
    getCreditBalance() {
      return `Rp. ${Intl.NumberFormat('en-US').format(this.data.credit_limit.balance || 0)}`
    },
    getMultipleBondAllowed() {
      return this.data.credit_limit.multiple_bond_allowed ?? 0
    },
    getCreditUsage() {
      return this.data.credit_limit.credit_limit_usage ?? 0
    },
  },
  mounted() {
    this.getPaymentMethod()
  },
  methods: {
    handleCancel() {
      this.modalVisible = false
    },
    handleCancelMixPayment() {
      this.modalVisibleMixPayment = false
    },
    getPaymentMethod() {
      const channel_id = this.$store.state.user.user_data.channel_id
      const seller_id = this.$route.params.seller_id
      this.$store
        .dispatch('purchase/GETPAYMENTMETHOD', {
          seller_id,
          channel_id,
        })
        .then(({ data }) => {
          this.data = data
          this.settlementPaymentList = this.data.payment_method.filter((obj) => {
            return !['CAD', 'COD'].includes(obj.payment_type.toUpperCase())
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleSelectPayment(value) {
      this.modalVisible = !this.modalVisible
      let selectedPayment
      let selectedPaymentImage
      ;(this.data.payment_method || []).forEach((list) => {
        list.payment_method.forEach((item) => {
          if (item.id === value) {
            selectedPayment = {
              term: list.payment_term,
              method_id: item.id,
              method: item.method,
              bank: item.bank_name,
              number: item.account_number,
              payment_name: item.payment_name,
              type: list.payment_type,
              pg_channel_id: item.pg_channels_id,
            }
            selectedPaymentImage = {
              term: list.payment_term,
              method_id: item.id,
              method: item.method,
              bank: item.bank_name,
              number: item.account_number,
              image: item.image,
              type: list.payment_type,
              payment_name: item.payment_name,
              payment_type: list.payment_type,
              pg_channel_id: item.pg_channels_id,
            }
          }
        })
      })
      if (selectedPayment !== undefined) {
        this.data_method = selectedPaymentImage
        this.remainingPrice = 0
        this.$emit('setSelectedPayment', selectedPayment)
      }
    },
    onChange(e) {
      this.seen = !this.seen
      if (this.seen) {
        this.$store.commit('loyalty/SET_LOYALTY_STORE', {
          usedPoints: Number(this.availablePoint),
        })
      } else {
        this.$store.commit('loyalty/SET_LOYALTY_STORE', {
          usedPoints: null,
        })
      }
    },
    onChangeSelectPoint() {
      this.seen_points = !this.seen_points
    },
    validationCreditLimit() {
      const creditLimit = this.data.credit_limit
      if (creditLimit.is_valid === false) {
        this.invalidStatusCredit = true
        // single bond
        if (creditLimit.multiple_bond_allowed === 1) {
          if (creditLimit.balance === 0) {
            this.selectedStatusCredit = 1
          } else {
            if (creditLimit.multiple_bond_allowed === creditLimit.credit_limit_usage) {
              this.selectedStatusCredit = 2
            } else {
              this.selectedStatusCredit = 1
            }
          }
        // double bond
        } else {
          if (creditLimit.balance === 0) {
            this.selectedStatusCredit = 1
          } else {
            if (creditLimit.multiple_bond_allowed === creditLimit.credit_limit_usage) {
              this.selectedStatusCredit = 3
            } else {
              this.selectedStatusCredit = 2
            }
          }
        }
      } else {
        this.invalidStatusCredit = false
        if (creditLimit.balance < this.totalPrice) {
          this.selectedStatusCredit = 0
        } else {
          this.selectedStatusCredit = null
        }
      }
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
}
</script>
<style lang="scss" scoped>
.form-select-coin {
  margin-top: 20px;
  height: 35px;
  width: 275px;
  font-size: 20px;
}
.checkout-cart {
  margin-bottom: 1rem;
  border: 0.716px solid var(--biz-base-20);
  border-radius: 10px;

  &__poin {
    background: var(--biz-brand-base);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: var(--biz-base-100);
    }
  }

  &__poin2 {
    background: var(--biz-brand-base);
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: var(--biz-base-100);
    }

    .content {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: var(--biz-accent-status-1);
      margin-left: 10px;
      vertical-align: top;
    }
  }

  &__calculation {
    background: var(--biz-brand-base);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--biz-base-100);
    font-style: normal;

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    .content {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: var(--biz-accent-status-1);
    }
  }

  &__pay-method {
    background: var(--biz-brand-base);
    align-items: center;
    color: var(--biz-base-100);
    font-style: normal;

    .span.ant-radio {
      float: right;
    }

    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      font-family: 'Open Sans';
    }

    .subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      font-family: 'Open Sans';
    }

    .title2 {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      font-family: 'Open Sans';
    }

    .list-card {
      background: var(--biz-brand-base);
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    .confirm-payment {
      background: var(--biz-base-100);
      border: 1px solid var(--biz-base-100);
      border-radius: 5px;
      color: var(--biz-brand-base);
      cursor: pointer;
      padding: 10px;
      font-size: 16px;
      font-weight: 600;
    }
    .confirm-payment:disabled {
      background-color: #5d5d5d;
    }
    .cancel-payment {
      background: var(--biz-brand-base);
      border: 1px solid var(--biz-base-100);
      border-radius: 5px;
      color: var(--biz-base-100);
      cursor: pointer;
      padding: 10px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__content {
    background: var(--biz-brand-base);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .warehouse-name {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: var(--biz-base-100);

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }

    .warehouse-address {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: var(--biz-primary-60);

      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    .product-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--biz-base-100);

      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    .product-total {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-right: 10px;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }

    .product-align {
      text-align: right;
    }

    .product-size {
      font-style: normal;
      font-weight: normal;
      color: var(--biz-base-20);

      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    .product-price {
      &__discount {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-decoration-line: line-through;
        color: var(--biz-primary-40);
      }

      &__selling {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--biz-base-100);
      }
    }

    .payment-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: var(--biz-base-100);
    }

    .payment-list {
      height: 35px;
      background: var(--biz-accent-2);
      border-radius: 8px;
      color: var(--biz-brand-base);
      padding: 0.5rem;
      cursor: pointer;
    }
  }

  .ant-cascader-picker {
    background: var(--biz-brand-base);
    border: 1px solid var(--biz-accent-2);
    box-sizing: border-box;
    border-radius: 5px;
    color: var(--biz-accent-2);

    ::placeholder {
      color: var(--biz-accent-2) !important;
    }
  }

  .payment-method {
    background: var(--biz-brand-base);
    box-sizing: border-box;
    border-radius: 10px;
    color: var(--biz-primary-40);
    height: 40px;
    background: rgba(237, 28, 36, 0.06);
    border-radius: 10px;

    &__calculation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--biz-primary-40);
      font-style: normal;

      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }

      .content {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: var(--biz-accent-status-1);
      }
    }
  }

  .mix-payment-method {
    background: var(--biz-brand-base);
    border: 1px solid var(--biz-primary-20);
    box-sizing: border-box;
    border-radius: 10px;
    color: var(--biz-base-100);
    height: 40px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    &__calculation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-style: normal;

      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }

      .content {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: var(--biz-accent-status-1);
      }
    }
  }

  .voucher {
    background: var(--biz-brand-base);
    border: 1px solid var(--biz-brand-base);
    box-sizing: border-box;
    border-radius: 10px;
    color: var(--biz-base-100);
    height: 40px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    &__calculation {
      background: var(--biz-brand-base);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--biz-primary-40);
      font-style: normal;

      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }

      .content {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: var(--biz-base-100);
      }
    }
  }
}
.list-card {
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.list-card2 {
  background: #f6f6f6;
  padding: 10px;
  border-radius: 10px;
}
select {
  padding: 10px;
  padding-right: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

/* For IE (thanks to @SaiManoj) */
select::-ms-expand {
  display: none;
}
// Force to style CSS (Not recommended) For Select Coin Box
select#list-coin {
  background-color: #265c9b !important;
  color: #e8f6fe;
  border-radius: 5px !important;
  height: 40px;
  width: 275px;
  line-height: 20px;
  outline: none; /*Add this*/
}
.pointer {
  cursor: pointer;
}
.radio-bank-background {
  background-color: #015ca1;
  width: 200px;
}
.tag-status {
  background-color: #E8F6FE;
  padding: 1px 15px;
  border: 1px solid;
  border-radius: 100px;
}
.tag-credit-usage {
  background-color: #015CA1;
  padding: 2px 15px;
  border-radius: 100px;
  color: #FFFFFF;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
}
.text-credit-balance {
  color: #F5831F;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.info-container {
  border-radius: 12px;
  gap: 0px 10px;
  .info-text {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.selected-payment {
  border: 1px solid #015CA1;
  border-radius: 12px;
  padding: 12px;
  &-container {
    background: #F6F6F6;
    border-radius: 12px;
    padding-left: 10px;
  }
}
.text-remaining-price {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  color: #F5831F;
}
.title-payment-method {
  display: flex;
  align-items: center;
  gap: 15px;
}
</style>
