<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
    <path
      d="M8.78919 0.0248699C6.14723 0.270594 3.74671 1.63354 2.26018 3.74348C1.72213 4.50686 1.21857 5.61752 1.01163 6.49557C0.842627 7.21308 0.804688 7.55709 0.804688 8.37945C0.804688 9.19852 0.842627 9.54253 1.01163 10.2633C1.26341 11.3379 1.84629 12.5207 2.57059 13.4315C3.98124 15.204 6.01962 16.3409 8.40634 16.6914C8.96164 16.7701 10.2654 16.7701 10.8207 16.6882C12.3796 16.4621 13.7178 15.9313 14.9491 15.0598C15.3837 14.7519 16.3219 13.8607 16.6461 13.4479C17.5635 12.2782 18.1223 11.007 18.3602 9.52615C18.4465 8.99867 18.4465 7.76022 18.3637 7.23274C18.0809 5.4963 17.3152 3.95316 16.1356 2.73437C14.7353 1.2928 12.966 0.391817 10.9345 0.0903969C10.424 0.0117645 9.29619 -0.020998 8.78919 0.0248699ZM11 3.35687C11.6381 3.64519 11.9106 4.307 11.6312 4.90001C11.5277 5.11297 11.2414 5.3849 11.0104 5.48319C10.3378 5.77478 9.49279 5.51923 9.17203 4.92295C9.04441 4.68377 9.02372 4.27751 9.13409 4.00558C9.23066 3.75986 9.52728 3.46499 9.7963 3.34704C10.1688 3.1865 10.6275 3.18978 11 3.35687ZM9.66524 6.7544C10.4723 6.91821 10.9034 7.47191 10.8379 8.2615C10.8 8.7038 10.7586 8.88072 10.4413 9.95862C10.0343 11.3216 9.98255 11.5411 9.9791 11.9179C9.9722 12.3929 10.0791 12.5174 10.5413 12.5731C10.7896 12.6026 11.1138 12.5666 11.4001 12.4715C11.5036 12.4388 11.5932 12.4093 11.5967 12.4093C11.6312 12.4093 11.4449 12.9794 11.4035 12.999C11.2759 13.0678 10.2309 13.4217 10.0446 13.461C9.78251 13.5134 9.14099 13.5134 8.87196 13.4577C8.55465 13.3955 8.30632 13.2677 8.07524 13.0482C7.77517 12.7664 7.70274 12.5764 7.70274 12.0817C7.70619 11.6132 7.74758 11.41 8.11663 10.1716C8.49602 8.89055 8.55465 8.65466 8.5581 8.29754C8.56155 7.9568 8.50292 7.82247 8.30287 7.7078C8.1994 7.65211 8.12697 7.64228 7.82001 7.64555C7.51994 7.64555 7.41302 7.66194 7.20608 7.73401L6.95775 7.8192L6.975 7.74712C6.98534 7.7078 7.01983 7.57347 7.05433 7.44898L7.11296 7.22619L7.30266 7.15411C7.68205 7.00667 8.34771 6.80027 8.5719 6.75768C8.87196 6.70198 9.40311 6.6987 9.66524 6.7544Z"
      :fill="color || '#FF0000'" />
  </svg>
</template>
<script>
export default {
  name: 'IconInformation',
  props: ['color'],
}
</script>
