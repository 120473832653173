<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_539_15791)">
  <path d="M13.9993 2.6665H1.99935C1.26297 2.6665 0.666016 3.26346 0.666016 3.99984V11.9998C0.666016 12.7362 1.26297 13.3332 1.99935 13.3332H13.9993C14.7357 13.3332 15.3327 12.7362 15.3327 11.9998V3.99984C15.3327 3.26346 14.7357 2.6665 13.9993 2.6665Z" stroke="#777777" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M0.666016 6.6665H15.3327" stroke="#777777" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_539_15791">
  <rect width="16" height="16" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>

<script>
export default {
  name: 'CreditCardIcon',
}
</script>

<style>

</style>
